.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #B19886;
  overflow: hidden;
}

.Body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 6rem);
}

.headerStyle {
  height: 4rem;
  background-color: #8E7A6B;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 3rem;
}

@media only screen and (max-width: 500px) {
  .App {
    height: auto;
  }

  .Body {
    height: auto;
  }

  .headerStyle {
    padding: 0 0 0 1rem;
  }
}

@media only screen and (min-height: 900px) {
  .Body {
    height: calc(100vh - 8rem);
  }

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}